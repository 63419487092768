<template>

<button class="up" @click="pageup()" v-show="up"><svg class="" viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><defs></defs><path d="M469.376 240.448v676.864c0 11.776 9.6 21.376 21.312 21.376h42.688c11.776 0 21.312-9.6 21.312-21.376v-677.76l304.896 304.96c8.32 8.32 21.824 8.32 30.144 0l30.208-30.208a21.312 21.312 0 0 0 0-30.144L527.744 91.968a21.312 21.312 0 0 0-24.704-4.032 21.312 21.312 0 0 0-6.144 4.288L104.704 484.48a21.312 21.312 0 0 0 0 30.144l30.144 30.144c8.32 8.32 21.888 8.32 30.208 0l304.32-304.32z"></path></svg></button>

    <nav :style="alpha" ref="nav1">
        <div class="logonav-container">
            <img class="logonav-img" src="@/assets/logo.png"  alt="Logo">
              <span class="logonav-text">盈方量化</span>
         </div>
          

        <ul class="nav-list" :style="navColorList">
            <li @click="changePage(1)">
                <router-link to="/" :class="listHover" :active-class="a3">主页</router-link>
            </li>
           
            <li><span :style="navColorList2"></span></li>
            <li @click="changePage(4)">
                <router-link to="/1" :class="listHover" :active-class="a3">关于</router-link>
            </li>
        </ul>
    </nav>
    <router-view></router-view>

    <footer>
        <div class="footer-1 footer-div">
         <div class="logo-container">
            <img class="logo-image" src="@/assets/logo.png"  alt="Logo">
              <span class="logo-text">盈方量化</span>
         </div>
            <p> &copy; 2024 南京盈方数字科技有限公司   版权所有 </p>
             <p> <img src="@/assets/icp.png" alt="公安备案图标" class="police-icon" />
            <a  href="https://beian.mps.gov.cn/#/query/webSearch?code=32010402001854" class="custom-link1" rel="noreferrer" target="_blank">苏公网安备32010402001854号</a>
            <a href="https://beian.miit.gov.cn/" class="custom-link" target="_blank">苏ICP备2024102363号-1
            </a>
            </p>
        </div>
        <div class="footer-line"></div>
        <div class="footer-3 footer-div">
            <p>微信小程序</p>
                    <div class="miniprogram">
                    <img class="centered-image" src="@/assets/QR1.png" width="80" height="80" alt="Centered Image">
                    </div>
         </div>
    </footer>
</template>




<style scoped>
.logonav-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-left: 80px;
}

.logonav-img {
  width: 48px;
  height: 48px;
}


.police-icon{
  width: 12px;
  height: 12px;
}   

.logonav-text {
  margin-left: 10px;
  color: rgba(0,43,127,0.8);
}
.logo-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}


.icp-image{
  width: 24px;
  height: 24px;
}

.logo-image {
  width: 48px;
  height: 48px;
  margin-left: 20px;
}

.logo-aa {
  width: 48px;
  height: 48px;
  margin-left: 40px;
  margin-top: 5px;
}

.logo-text {
  margin-left: 10px;
  color: gray;
}

.custom-link1 {
  color: gray; 
   margin-left: 1px;
}

.custom-link {
  color: gray; 
   margin-left: 10px;
}
 
.custom-link:hover {
  color: white; 
}

.custom-link1:hover {
  color: white; 
}
</style>



<style scoped>
@import '@/css/mainStyle.css';
</style>



<script>

export default {
    name: "main",
    components: {
    },
    data() {
        return {
            navColorList: "color:white",
            navColorList2: "background-color:white",
            listHover: "a1",
            page: 1,
            a3: "a3",
            alpha: "background-color:rgba(255,255,255,0)",
            oldScrollTop: 0,
            up:false,
        }
    },
    mounted() {
        window.addEventListener("scroll", this.scroing, true)
    },
    created() {
        if (window.location.pathname == "/place") {
            this.page = 4;
        }
    },
    methods: {
        pageup(){
            document.documentElement.scrollTo(0,0);
        },
        scroing() {
            if (document.documentElement.scrollTop <= window.innerHeight - 32 && this.page == 1) {
                this.navColorList = "color:white";
                this.navColorList2 = "background-color:white";
                this.listHover = "a1";
                this.a3 = "a3"
                this.alpha = "background-color:rgba(255,255,255,0)"
                this.up=false
            } else {
                this.navColorList = "color:black";
                this.navColorList2 = "background-color:black";
                this.listHover = "a2";
                this.a3 = "a4"
                this.alpha = "background-color:rgba(255,255,255,0.8);backdrop-filter:blur(6px)";
                this.up=true
            }
            let scrollTop = document.documentElement.scrollTop
            let scrollStep = scrollTop - this.oldScrollTop;
            this.oldScrollTop = scrollTop;
            if (scrollStep < 0) {
                this.$refs.nav1.style.transform = "translateY(0px)"
            } else {
                this.$refs.nav1.style.transform = "translateY(-5px)"
            }
        },
        changePage(num) {
            this.page = num;
        }

    },
    computed: {
    },
    watch: {
        page: function () {
            if (this.page == 1) {
                this.navColorList = "color:white";
                this.navColorList2 = "background-color:white";
                this.listHover = "a1";
                this.a3 = "a3";
                this.alpha = "background-color:rgba(255,255,255,0)"

            } else {

                this.navColorList = "color:black";
                this.navColorList2 = "background-color:black";
                this.listHover = "a2";
                this.a3 = "a4";
                this.alpha = "background-color:rgba(255,255,255,0.8);backdrop-filter:blur(6px)";
            }

        }
    }
}

</script>