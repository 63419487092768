<template>


<div class="banner">
    <div class="banner-title">
        <p class="banner-title-title">量化策略智能体</p>
        <p class="banner-title-title2">NYSEAI</p>
   

    </div>
    <div class="banner-buttom" @click="pageDown()">
        <button><svg class="arrow-down_svg__icon" viewBox="0 0 1024 1024" width="18px" height="18px" fill="white"><path d="M486.969 1014.101l-.683-.625-306.745-315.279a34.133 34.133 0 0 1 0-47.616l3.015-3.072a34.133 34.133 0 0 1 48.242-.682l.683.682 238.478 245.078-.057-858.454A34.133 34.133 0 0 1 504.036 0h3.3a34.133 34.133 0 0 1 34.132 34.133v870.97l250.653-257.594a34.133 34.133 0 0 1 48.242-.682l.682.682 3.015 3.072a34.133 34.133 0 0 1 0 47.616l-306.744 315.279a34.076 34.076 0 0 1-26.909 10.24h-2.105a34.02 34.02 0 0 1-21.333-9.615z"></path></svg></button>
        <div class="banner-buttom-line">
        </div>
    </div>
    <div class="banner-image">
        <img data-aos="fade-left"  data-aos-duration="800"    src="@/assets/detail/222.png" alt="">
    </div>
</div>





<div class="mod4">
   

    <div class="mod4-1">
        <div class="mod4-1-detail">
            <title2 t1="量化策略智能体" t2="NYSEAI" type="type2"></title2>
            <detail1 title="数据分析与知识问答" :content="['美股港股行情、财务报表、新闻事件等金融数据处理分析和知识问答']"></detail1>
            <detail1 title="策略优化与自我学习" :content="['识别不同交易策略的优劣，并根据实时市场情况进行动态调整，提高稳定性和盈利能力']"></detail1>
        
        </div>
        <div class="mod4-1-img">
            <img data-aos="fade-left" data-aos-duration="800" src="@/assets/detail/2.png" alt="">
        </div>
    </div>

    <div class="mod4-1">
        <div class="mod4-1-img">
            <img data-aos="fade-right" data-aos-duration="800" src="@/assets/detail/3.png" alt="">
        </div>
        <div class="mod4-1-detail">
            <title2 t1="量化策略" t2="NYSEAI" type="type2" detail="基于概率论和机器学习的量化策略模型，覆盖股票、ETF、期权等资产"></title2>
            <ul data-aos="fade-up" data-aos-duration="800">
                <li>基本面量化策略</li>
                <li>市场中性策略</li>
                <li>事件驱动策略</li>
                <li>期权量化策略</li>
                <li>多因子量化策略</li>
            </ul>
        </div>
    </div>


    

    <div class="mod4-1" style="flex-wrap: wrap;">
        <div class="mod4-1-detail">
            <title2 t1="AI数据服务" t2="NYSEAI" type="type2"></title2>
            <detail1 title="财报分析" :content="['识别和解释关键财务指标，提供财务健康诊断、预测未来表现、风险评估']"></detail1>
            <div style="height: 50px;"></div>
        </div>
        <div class="mod4-1-img">
            <img data-aos="fade-left" data-aos-duration="800" src="@/assets/detail/7.png" alt="" style="border-radius: 8px;">
        </div>
        <div class="mod4-1-detail2">
            <div class="mod4-1-detail2-p">
                <detail1 title="AI语料" :content="['美港上市公司行情、业绩、招聘、新闻、分析报告、突发事件等多模态AI语料']"></detail1>
            </div>
            <div class="mod4-1-detail2-img">
                <img data-aos="fade-left" data-aos-duration="800" src="@/assets/detail/8.png" alt="">
                <img data-aos="fade-left" data-aos-duration="800" src="@/assets/detail/9.png" alt="">
            </div>
        </div>
    </div>

</div>

<div class="mod5">
    <div class="mod5-title">
        <maintitle t1="合作方式" ></maintitle>
    </div>

    <div class="mod5-story">
        <bdstory v-for="story in storys" :msg="story"></bdstory>
    </div>


</div>
 

<br>
<br>
<br>
</template>
<style scoped>
@import '@/css/homeStyle.css';
</style>

<script>
import 'aos/dist/aos.css'
import AOS from 'aos/dist/aos.js'
import products from "@/components/products.vue"
import title2 from "@/components/title.vue"
import detail1 from '@/components/detail1.vue'
import maintitle from '@/components/maintitle.vue'
import bdstory from '@/components/story.vue'
export default {
    name: "home",
    created(){
        AOS.init({once:true,offset:60});
        document.title="盈方科技";
    },
    methods:{
        pageDown(){
            document.documentElement.scrollTop =window.innerHeight
        },
    },
    data(){
        return{
                
    
            storys:[
                {
                    title:'策略定制',
                    detail:'我们为您提供最优的合作方案，满足您的定制需求。'
                },
                {
                    title:'策略调优',
                    detail:'我们将与您展开深度合作，持续迭代策略，参数最优。'
                },
                {
                    title:'结果共盈',
                    detail:'我们核心目标是长期稳定获取收益盈余。'
                },
            ]
        }
    },
    components:{
        products,
        title2,
        detail1,
        maintitle,
        bdstory
    },
}
</script>